import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/Info/SuccessBox';
import InfoTable01 from 'components/Web_User_Interface/1080p_Series/System/Info/infoTable01';
import InfoTable02 from 'components/Web_User_Interface/1080p_Series/System/Info/infoTable02';
import InfoTable03 from 'components/Web_User_Interface/1080p_Series/System/Info/infoTable03';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Overview",
  "path": "/Web_User_Interface/1080p_Series/System/Info/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address) - to check whether it is the DDNS address or your port forwarding that is the culprit.",
  "image": "./WebUI_1080p_SearchThumb_System_Overview.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_System_Overview.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='System Menu // Overview' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address) - to check whether it is the DDNS address or your port forwarding that is the culprit.' image='/images/Search/WebUI_1080p_SearchThumb_System_Language.png' twitter='/images/Search/WebUI_1080p_SearchThumb_System_Language.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/System/Info/' locationFR='/fr/Web_User_Interface/1080p_Series/System/Info/' crumbLabel="System Info" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/fbfd6/1080p_Settings_System_Info-Overview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC4UlEQVQoz42SS0wTURSGByRGfIQiryDhkcYixAJCwEdQkGCCykMMggpRFhJUQEWnM/fO0OlM6UxXxAXSFhdi3OnKuDFxQVA0gCYGWBgCCq08KgJ9RNpCgfaYtujClV9O7uLe853/LC5B0zRCCGHMIAqJ3fTTz/cej7SYhltNI229H9t6R1pNw7cNQy3GIfrZOHr4XI1UzU3NOTl5mZlKgqIRRSOGYVUqsrvH5N6EVS8sO9dXnF6ba2tldcO55rO7fc413wbAh/7BtqqLty5cqS8qKQnJKgoxLKsiSaPBAAAOm21+4bvjlw0AwO9bXvo5+G5g+usUAEyOfBIa7nPVjVRZUdWRgqD8J9lgMAKAzWZfWLLaPQ4IMjEx8aSvz2q1AsDYwJB4tcbUrqoua81WHttem2VDsgH8sLS8ZLfZN70bfp8PAOZmZ0dHR+fnZn0Azi9v39/NHu+69EYi1ecLCIwZisYsy5IkaTQGkl0ul9vt8nq9m1t+P/jX19c9Ho/FYgGA1y9fFKUlorNprxrj+28oAjJJIYZhKIrq6uqam5s3m81mi3lmesa/tux2LJqnLd+mJhd/zANs9TwyJSUkH0hMSYmLvZMfTTAMQyNM0Yjn+YqKyihZdFx8QpQstvaUvKkyS1ubp6mvOpOXoWFx4/VryoxMhSI9Jlom27u7ODVyO1lF0VqttrS0lCCIHeFhuTGEIoYgiLCy1H1j1bLW9F2FFeVZJSflGfIkeXLknp0REeH5sUQgmUKIRkij0dTV1sYlJtcUHrx5TrE/Ma3gcKYuK/uB4lCJMvt4+em69obLLfUnqopzj+Ypc7Pk6elEcGeMGQZjzPO8Xq/X6SSNIOr1kiSKgk5kJYkXxU5BK2gEnU4nBpEkSS+KBMJMQA3KHMcJAs/zfOgM0SkIWiGIVvv3Ugh2ECzLsEygMMZqNSf8B39HEKrAJ8EkjWiE1QE4NcdxmgAcx4VC/jFDTx0dHb8Begq5rBCxAkkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/e4706/1080p_Settings_System_Info-Overview.avif 230w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/d1af7/1080p_Settings_System_Info-Overview.avif 460w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/7f308/1080p_Settings_System_Info-Overview.avif 920w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/d6018/1080p_Settings_System_Info-Overview.avif 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/a0b58/1080p_Settings_System_Info-Overview.webp 230w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/bc10c/1080p_Settings_System_Info-Overview.webp 460w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/966d8/1080p_Settings_System_Info-Overview.webp 920w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/00474/1080p_Settings_System_Info-Overview.webp 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/81c8e/1080p_Settings_System_Info-Overview.png 230w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/08a84/1080p_Settings_System_Info-Overview.png 460w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/c0255/1080p_Settings_System_Info-Overview.png 920w", "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/fbfd6/1080p_Settings_System_Info-Overview.png 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2cf9d5cfd4fae982bf665fa68bf68b0b/c0255/1080p_Settings_System_Info-Overview.png",
              "alt": "Web User Interface - 1080p Series - System Info",
              "title": "Web User Interface - 1080p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable01 mdxType="InfoTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/fbfd6/1080p_Settings_System_Info-Network.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC40lEQVQoz42SS0wTURSGByUm+AIs1SDhEWJ5xFIsAR/BgA0LVApigGohyEKCKBjR6cy9UzqdKZ3pqjEEKoMr464rDBI0mKBRHmqMCsGVi1ItKmLl0QItLZ1r2iILV345uYt77nf/szgYSZIAAAAhBQjA9RAP3rf3TbT2jrfaJq7fm2wT3kSrXXhNPpwGd+0GoGtpbsnPL8jNlWMECQgSUJRep8N7bMJaEPlF5NkQvQG0vB5cWPYt+8SFFf/v1WAAofHRV+1VF1svXK4vUamiso4AlF6P43hfX58ohp6NPB16PDj4aGD644dVz8rC/A+vxyOKIkLo89t3bMNturqJKC+pOlYUkSPJOI4LghAIBKxWq1arbWy8MjQ05Pf7HQ7HL7d7c3MTITT1fJLT1ggduuryNoX8xNbY+r/JPp+vu7tbrVZrNJonw8Pr62tfnM6ZmZlvcy6E0NKnF2O3FNPW2hEeN5wvwiCkCBJGZUHoD4VCdrv9fn+/zWZ7OTYRDIkbG/4Vj8fr9SKEhgfsJRnJ4GzGYNPB0auysIwTgKIogiCsVqvL5XI6nbNOp8MxK/rda4vzrq9zP+e/Ly26EQraevtTDqUeTk5LkybdLEzEKIoiASRIwDCMWl0Zn5CYJJXuj5fUFmc0q/NMtUpaW6nKl9GQbGpqlOfkymRZksSEhL27S9PjtpJ1BGkymcrKyjAM27kjRinBZBIMw2LK0/dNVSe0ZccVqyvyVKczczJTMlPj9uyKjd1RmISFkwkASACMRqOmrk6anFpTfOTaOdmB5Iyio7nmPMUdWbZKrjhZcUbT0XDpRv2pqlLl8QK5Mi8zKwuLzAwhRUEIGYaxWCxmM29kOYuF5zmONXN6nmc4ros1sUbWbDZzEXiet3AcBiAVViMyTdMsyzAMEz2jdLGsiY1gMm1fspEXmF5P6alwQQgNBpr9D7a/wHThJYE4CUgADWFoA03TxjA0TUdD/jGjrc7Ozj83CapXuApEmgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/e4706/1080p_Settings_System_Info-Network.avif 230w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/d1af7/1080p_Settings_System_Info-Network.avif 460w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/7f308/1080p_Settings_System_Info-Network.avif 920w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/d6018/1080p_Settings_System_Info-Network.avif 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/a0b58/1080p_Settings_System_Info-Network.webp 230w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/bc10c/1080p_Settings_System_Info-Network.webp 460w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/966d8/1080p_Settings_System_Info-Network.webp 920w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/00474/1080p_Settings_System_Info-Network.webp 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/81c8e/1080p_Settings_System_Info-Network.png 230w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/08a84/1080p_Settings_System_Info-Network.png 460w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/c0255/1080p_Settings_System_Info-Network.png 920w", "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/fbfd6/1080p_Settings_System_Info-Network.png 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/97e881bb9e3e9f2d3fd3a6a0aed9883a/c0255/1080p_Settings_System_Info-Network.png",
              "alt": "Web User Interface - 1080p Series - System Info",
              "title": "Web User Interface - 1080p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable02 mdxType="InfoTable02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/fbfd6/1080p_Settings_System_Info-Downloads.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC1ElEQVQoz42SS0wTQRyHFyUm+EgKFhEJDwkFGlteAR/BFCsHfABiEBSIcpCgsVWR7u7MLN3uLt3tCS5IbePFxJNeTDx48EA0SgRCTMAHARWiAhKhLWpBpdvtmG2Rgye//GcymZlvfjPJEDRNAwAAhAhQQOyj77y85h2yeIas3mGrd8TiGbZ41IHVMwTujtO99+yAbG9rLyws0esNBEUDigYIMSRp6+v3rITwqoyXfqz5grJ/Ra1AMPRlKegPyjLGzweeWWtPXz51rtlkNsdkkgKIYUibze3uxxhPTU0uB/wYRyKRyOrvUPBXSFGUiKJgjN+NjPItN9i6VqrKVFtUFpX/JrvdtzDGAd/Sh6nJb34fxjgky3JY1ZRwGGM89uSF2FTv6SDrqiwFhgPr12YYhiRJt9stK5HFn/Lrmbn381+xmq4oalOUsIwxXn77dPB64XjPmceSzX6ijIAQUTRkGKbTZrvt9SyvYWE4AAcDnlff1cAIjhFWn4wfPbhvykoFx7Ietu4auKhTZRsFEEIURfX29Hz8PDs8MTP4Znp0Yhqv+VcCC7Of5hbm5/y+RYzD/Te9aSnpe1IzMpK1V0sTCYQQDSBFA47jqqtrNImJu1NStEnaJtPethqj0FDCNtUeLcpzINB64bwhX6/T5e5M1Gi2b63ITFhPJilaEITKykqCIDbHxRUlETlJBEHEVWXuGKvTWPISyqtPGs2Hs/Oz07LTE7ZtiY/fVKol1GQKABoAh8PR2NCQnJpeX55z6bguKTWrbJ/eaSzo1OWZDQUHTx5p7Gg5e6X5UG1F8f4SQ7ExOzeXiN4ZQoQghBzHuVwup1Ny8KLLJUmiyDtFRpI4UezmBd7BO51OMYokSS5RJABEqhqVWZbleY7juFgfo5vnBT6KIGxM8tEdBMMgBqkFIbTbWf4/2DiCINVPAm00oAG0q7B2lmUdKizLxkL+MWNLXV1dfwDpVbuYJud2rAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/e4706/1080p_Settings_System_Info-Downloads.avif 230w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/d1af7/1080p_Settings_System_Info-Downloads.avif 460w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/7f308/1080p_Settings_System_Info-Downloads.avif 920w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/d6018/1080p_Settings_System_Info-Downloads.avif 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/a0b58/1080p_Settings_System_Info-Downloads.webp 230w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/bc10c/1080p_Settings_System_Info-Downloads.webp 460w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/966d8/1080p_Settings_System_Info-Downloads.webp 920w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/00474/1080p_Settings_System_Info-Downloads.webp 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/81c8e/1080p_Settings_System_Info-Downloads.png 230w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/08a84/1080p_Settings_System_Info-Downloads.png 460w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/c0255/1080p_Settings_System_Info-Downloads.png 920w", "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/fbfd6/1080p_Settings_System_Info-Downloads.png 1357w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1fdb7c9faf1ab0ba9b1c266f9794a9fd/c0255/1080p_Settings_System_Info-Downloads.png",
              "alt": "Web User Interface - 1080p Series - System Info",
              "title": "Web User Interface - 1080p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable03 mdxType="InfoTable03" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      